import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BASE_PATH } from 'generics/constants';
import default_image from '../../../assets/images/final.png';

import './Image.scss';

const DIRECTION = {
  H: 'width',
  V: 'height',
};

const Image = ({ image, width, height, direction, bg, quality }) => {
  const [item, setItem] = useState({
    image: bg,
  });
  const style = {
    width,
    height,
  };

  useEffect(() => {
    if (!image) {
      return;
    }

    setItem({
      image: `${BASE_PATH.FILES}/v1/assets/images/${direction}/${quality}/${image}`,
    });
  }, [image, quality, direction]);

  return (
    <div className="component-image" style={style}>
      {
        item && <img alt="" src={item.image} />
      }
    </div>
  );
};

Image.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  direction: PropTypes.string,
  bg: PropTypes.any,
  quality: PropTypes.number.isRequired,
};

Image.defaultProps = {
  image: null,
  width: 'auto',
  height: 'auto',
  bg: default_image,
  direction: DIRECTION.H,
};

Image.H = DIRECTION.H;
Image.V = DIRECTION.V;

export { Image };
