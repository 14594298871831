import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BASE_PATH } from 'generics/constants';
import default_image from '../../../assets/images/final.png';

import './HImage.scss';

const HImage = ({ image, width, height, quality }) => {
  const [item, setItem] = useState({
    image: default_image,
  });
  const style = {
    width,
    height,
  };

  useEffect(() => {
    setItem({
      image: image ? `${BASE_PATH.FILES}/v1/assets/images/width/${quality}/${image}` : default_image,
    });
  }, [image, quality]);

  return (
    <div className="component-image" style={style}>
      {
        item && <img alt="" src={item.image} />
      }
    </div>
  );
};

HImage.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  quality: PropTypes.number.isRequired,
};

HImage.defaultProps = {
  image: null,
  width: 'auto',
  height: 'auto',
};

export { HImage };
