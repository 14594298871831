import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import Select from 'react-select';

import { api, toast } from 'generics/services/globals/v3';
import { MultiSelect as V2MultiSelect } from 'components/forms/v2';
import { ContentTitle } from 'components/titles/v2';
import { Flex } from 'components/grids/v2';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { RemoveConfirmation } from 'components/generics/v1';
import { UImage, UImageB } from 'components';
import { IButton } from 'components/buttons/v2';

import { ITEM_HEADER, BUTTON_LABELS } from './constants';

const colourOptions = [
  { value: 'la paz', label: 'La Paz' },
  { value: 'cbba', label: 'Cbba' },
  { value: 'oruro', label: 'Oruro' },
  { value: 'santa cruz', label: 'Santa Cruz' },
  { value: 'potosi', label: 'Potosi' },
  { value: 'chuquisaca', label: 'Chuquisaca' },
  { value: 'tarija', label: 'Tarija' },
  { value: 'beni', label: 'Beni' },
  { value: 'pando', label: 'Pando' },
];
const select_color_config = {
  // eslint-disable-next-line
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? '#111111' : null,
    color: '#777777',
  }),
};

const GItem = ({ route, uri, area }) => {
  const { _id } = useParams();
  const history = useHistory();
  const [is_new] = useState(_id === undefined);
  const {
    item,
    set_item,
    on_number_change,
    on_string_change,
    on_image_change,
  } = useFormReducer({
    area_id: null,
    anniversary: new Date().toISOString().slice(0, 10),
    is_approve: true,
  });
  const [city, set_city] = useState(null);

  const create = () => {
    if (!city) {
      toast.danger('No registraste el departamento');
      return;
    }

    item.city = city.value;
    api.post(uri.post, null, item)
    .then(() => {
      history.push(route.list);
    })
    .then(() => {
    })
    .catch(toast.api_danger);
  };

  const update = () => {
    item.city = city.value;
    api.put(uri.put, { _id }, item)
    .then(() => {
      history.push(route.list);
    })
    .catch(toast.api_danger);
  };

  const on_submit = (e) => {
    e.preventDefault();
    if (is_new) {
      create();
    } else {
      update();
    }
  };

  const on_remove_callback = useCallback(() => {
    api.remove(uri.remove, { _id }, item)
    .then(() => {
      history.push(route.list);
    })
    .catch(toast.api_danger);
  }, [uri, _id, history, route, item]);

  const on_categories_change = (_ids) => {
    set_item({ ...item, categories: _ids });
  };

  useEffect(() => {
    if (is_new) {
      set_city(colourOptions[0]);
      return;
    }

    api.get(uri.get_by_id, { _id }, null)
    .then(({ data }) => {
      set_item(data);
      set_city(colourOptions.find((o) => o.value === data.city));
    })
    .catch(toast.api_danger);
  }, [is_new, _id, uri, area, set_item]);

  const render_buttons = () => {
    if (is_new) {
      return <IButton label={BUTTON_LABELS.CREATE} icon="checkmark" submit />;
    }

    return (
      <>
        <RemoveConfirmation label={BUTTON_LABELS.REMOVE} on_click={on_remove_callback} />
        <IButton label={BUTTON_LABELS.UPDATE} icon="checkmark" submit />
      </>
    );
  };

  const on_group_change = (data) => {
    set_city(data);
  };

  return (
    <>
      <ContentTitle config={ITEM_HEADER} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>SIGLA / Diminutivo</label>
          <input name="code" placeholder="CAFT, TVC, CP, UAB" value={item.code} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Nombre completo del colegio</label>
          <input name="name" placeholder="Nombre completo" value={item.name} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Cantidad de integrantes de la banda</label>
          <input name="quantity" placeholder="Cantidad" type="number" value={item.quantity} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Aniversario</label>
          <input name="anniversary" placeholder="Aniversario" type="date" value={item.anniversary} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Ciudad</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            value={city}
            name="color"
            options={colourOptions}
            onChange={on_group_change}
            styles={select_color_config}
          />
        </Form.Field>
        {/* <Form.Field>
          <Checkbox
            name="is_approve"
            checked={item.is_approve}
            label="Este colegio esta aprobado"
            onChange={on_checkbox_change}
          />
        </Form.Field> */}
        <Form.Field>
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripción" value={item.description} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <V2MultiSelect selector="name" placeholder="Concurso asistidos" path="/v1/events" params={{}} _ids={item.categories} on_select={on_categories_change}>
            <label>Concurso asistidos</label>
          </V2MultiSelect>
        </Form.Field>
        <h3>Logo</h3>
        <UImage image={item.image_id} height="150px" quality={1080} on_callback={on_image_change} />
        <h3>Foto de la banda completa</h3>
        <UImageB image={item.band_id} height="150px" quality={1080} on_callback={on_image_change} />
        <Flex.End>
          {render_buttons()}
        </Flex.End>
      </Form>
    </>
  );
};

GItem.propTypes = {
  route: PropTypes.any.isRequired,
  uri: PropTypes.shape({
    get: PropTypes.string.isRequired,
    get_by_id: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    put: PropTypes.string.isRequired,
    remove: PropTypes.string.isRequired,
  }).isRequired,
  area: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export { GItem };
